import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import ComingSoon from "react-coming-soon";


class SiteIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    return (
      // <h1>Hi</h1>
        <ComingSoon
              title="Mo's Portfolio"
              subtitle="Coming Soon"
              image="https://react-coming-soon.maksv.me/default-image.jpeg"
              bgColor="#fff"
              textColor="#000"
              illustration="development"
            />
    );
  }
}

export default SiteIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
// export default function App() {
//   return (
//     <ComingSoon
//       image="https://react-coming-soon.maksv.me/default-image.jpeg"
//       bgColor="#fff"
//       textColor="#000"
//       illustration="development"
//     />
//   );
// }